@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Nunito:wght@200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

body {
  background-color: #0d1341;
}

.App {
  width: 375px;
  margin: 80px auto;
  padding: 30px 16px;
  background: #000740;
  /* border: 1px solid #605f68; */
  border-radius: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
}

.place-h {
  width: 100%;
  border-radius: 16px;
  border: 1px dashed white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 50px 0;
}

.place-h img {
  width: 70%;
}

.d-link {
  color: white;
  background-color: #f07742;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 12px;
  margin-top: 20px;
}

.conn-text {
  margin-top: 30px;
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.conn-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  margin-bottom: 25px;
  background-color: #f07742;
  border: none;
  border-radius: 16px;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  padding-right: 30px;
}

.conn-btn:hover {
  background-color: #db6735;
}

.fox {
  margin-right: 15px;
}

.conn-btn-active {
  background-color: #333966;
  color: #00cc9b;
  padding-right: 0;
}

.conn-btn-active:hover {
  background-color: #333966;
}

.nft-img {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 16px;
}

input[type="text"] {
  width: 100%;
  height: 44px;
  margin-bottom: 20px;
  margin-top: 10px;
  padding-left: 16px;
  border: none;
  border-radius: 10px;
  background: #252159;
  color: #a8a6bd;
  outline: none;
}

input[type="text"]::placeholder {
  color: #a8a6bd;
}

label {
  font-weight: 400;
  color: #999cb3;
}

.mint-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  background-color: #333966;
  margin-top: 13px;
  border: none;
  border-radius: 16px;
  color: #cccdd9;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.mint-btn-active {
  background-color: #ff53f4;
  color: #ffffff;
}

.feedback-cont {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.35);
  display: none;
  align-items: center;
  justify-content: center;
}

.success-cont {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.35);
}
.success-feedback {
  position: fixed;
  width: 343px;
  height: 355px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  overflow: hidden;
}

.success-feedback h2 {
  font-size: 20px;
  margin-bottom: 20px;
  padding: 0 32px;
  font-weight: 700;
}

.success-feedback p {
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: 400;
  padding: 0 32px;
}

.success-feedback p:nth-child(6) {
  padding: 0 12px !important;
}

.success-feedback a {
  color: white;
}

.succ-bg {
  position: absolute;
  z-index: -1;
}

.close-succ {
  width: 32px;
  height: 32px;
  background-color: #af6ac0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: 20px;
}

.close-succ:hover {
  background-color: #9e5aaf;
}

@keyframes hand {
  0% {
    transform: translateX(-200px);
  }
  100% {
    transform: translateX(-115px);
  }
}

.pr-hand {
  margin-bottom: 20px;
  transform: translateX(-115px);
  animation: 1s hand ease;
}

.feedback p {
  width: 370px;
  padding: 20px;
  border-radius: 12px 12px 0 0;
  background-color: #000740;
  color: white;
}

.close-btn {
  background-color: #f07742;
  color: white;
  padding: 12px;
  text-align: center;
  border-radius: 0 0 12px 12px;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #db6735;
}

.loading-circle {
  width: 35px;
}

@media all and (max-width: 768px) {
  .App {
    width: 100%;
    margin: 0 auto;
    padding: 40px 16px;
    background: #000740;
    border-radius: 0;
  }
}
